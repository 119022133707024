
export default {
        data() {
                return {
                        currentlyYear: null,
                        lastYear: null,
                        cars: [
                                {
                                        link: '/cars/sedan',
                                        title: 'Седан',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-9@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-9@x2.webp')
                                        }
                                },
                                {
                                        link: '/cars/coupe',
                                        title: 'Купе',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-1@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-1@x2.webp')
                                        }
                                },
                                {
                                        link: '/cars/universal',
                                        title: 'Универсал',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-2@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-2@x2.webp')
                                        }
                                },
                                {
                                        link: '/cars/hatchback',
                                        title: 'Хэтчбэк',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-3@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-3@x2.webp')
                                        }
                                },
                                {
                                        link: '/cars/allroad',
                                        title: 'Внедорожник',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-4@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-4@x2.webp')
                                        }
                                },
                                {
                                        link: '/cars/liftback',
                                        title: 'Лифтбэк',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-5@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-5@x2.webp')
                                        }
                                },
                                {
                                        link: '/cars?body_type_id_array=31',
                                        title: 'Пикап',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-6@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-6@x2.webp')
                                        }
                                },
                                {
                                        link: '/cars?body_type_id_array=9',
                                        title: 'Компактвэн',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-7@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-7@x2.webp')
                                        }
                                },
                                {
                                        link: '/cars/minivan',
                                        title: 'Минивэн',
                                        image: {
                                                x1: require('~/assets/img/makes-upper/Frame-8@x1.webp'),
                                                x2: require('~/assets/img/makes-upper/Frame-8@x2.webp')
                                        }
                                },

                        ],
                        initSlider: false
                }
        },
        mounted() {
                // let leftPadding = this.$refs.benefitsSlider.getBoundingClientRect().left
                // let width = this.$refs.benefitsSlider.getBoundingClientRect().width
                // let indexOfView = leftPadding * 2 / (width / 3) + 3
                new swiper.default('.swiper--options_upper.swiper', {
                        modules: [swiper.Navigation, swiper.Autoplay],
                        loop: false,
                        // autoplayDisableOnInteraction: true,
                        autoplay: false,
                        initialSlide: 0,
                        // centeredSlides: true,
                        watchSlidesProgress: true,
                        // centeredSlidesBounds:true,
                        // roundLengths: true,
                        // freeMode:true,
                        // loop:true,
                        // centerInsufficientSlides:true,
                        // slidesOffsetAfter: this.$refs.benefitsSlider.getBoundingClientRect().left,
                        // cssMode:true,
                        slidesPerView: 3.3,
                        spaceBetween: 35,
                        // slidesOffsetBefore: leftPadding,
                        // slidesOffsetAfter: leftPadding,
                        breakpoints: {
                                // when window width is >= 320px
                                480: {
                                        slidesPerView: 4.3
                                },

                                640: {
                                        slidesPerView: 5.3
                                },
                                900: {
                                        slidesPerView: 7.3
                                },
                                1000: {
                                        slidesPerView: 9
                                }
                        },
                        on: {
                                init: (value) => {
                                        this.initSlider = true
                                }
                        }
                });
        }
}
